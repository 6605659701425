<template>
    <phi-page>
		<div slot="toolbar">
			<mu-icon-button
                @click="$router.go(-1)"
                icon="arrow_back"
            />
			<h1>{{ $t('action.track') }}</h1>
            <mu-switch
                v-if="route"
                :value="tracker.isEnabled"
                @change="tracker.toggle(route.target.id)"
            >
            </mu-switch>
		</div>

        <gmap-map
            ref="mainMap"
            style="width: 100%; height: 100%; min-height: 600px"
            :center="center"
            :zoom="zoom"
        >
            <!--<gmap-marker v-if="tracker.lastPosition"
                :position="{lat: tracker.lastPosition.coords.latitude, lng: tracker.lastPosition.coords.longitude}"
            ></gmap-marker>-->
        </gmap-map>

    </phi-page>
</template>

<script>

import * as VueGoogleMaps from 'vue2-google-maps';
import Vue from "vue";
import app from '../../../store/app.js';

Vue.use(VueGoogleMaps, {
	load: {
        key: "AIzaSyCpSKazmH7T7ArBkdcPiRYaYG_QKhj7rRM",
        libraries: "geometry",
        v: "3.32"
    }
});

export default {
	data() {
		return {
			app,
			center: {
                lat: app.data.organization ? parseFloat(app.data.organization.latitude) : 0,
                lng: app.data.organization ? parseFloat(app.data.organization.longitude) : 0
            },
            zoom: 15,
            route: null,
            map: null,
            carImage: null,
            infoMarker: null,
            mapMarker: null,
            isVisible: false,
            routeId: null
		}
	},

    computed: {
        tracker() {
            return {
                isEnabled: this.$store.state.geo.tracker != null,
                lastPosition: this.$store.state.geo.tracker != null ? this.$store.state.geo.tracker.lastPosition : null,
                enable: () => this.$store.dispatch("enable", this.route.target.id),
                disable: () => this.$store.dispatch("disable"),
                toggle: () => this.tracker.isEnabled ? this.tracker.disable() : this.tracker.enable()
            }
        }
    },

    mounted() {
        /* For vue2-google-maps => 0.10.2*/
        VueGoogleMaps.loaded.then(() => {
            this.$refs.mainMap.$mapPromise.then(map => {
                this.map = map;
                app.api
                    .get(`/v3/transport/routes/${this.$route.params.routeId}/`)
                    .then(response => {
                        this.route     = response
                        this.routeId   = response.target.id

                        this.carImage  = {
                            url: this.route.target.icon,
                            origin: new google.maps.Point(0, 0)
                        };

                        var lat = null;
                        var lng = null;

                        if (this.tracker.lastPosition) {
                            lat = this.tracker.lastPosition.coords.latitude;
                            lng = this.tracker.lastPosition.coords.longitude;
                        } else {
                            lat = this.center.lat;
                            lng = this.center.lng;
                        }

                        this.mapMarker = new google.maps.Marker({
                            position: new google.maps.LatLng(lat, lng),
                            map: this.map,
                            icon: this.carImage,
                            draggable: false
                        });

                        this.infoMarker = new google.maps.InfoWindow({
                            content: "<h3>" + this.route.name + "</h3>"
                        });
                });
            });
        });
    },

    watch: {
        "tracker.lastPosition"(position) {
            if (!position) {
                return;
            }

            let newPosition = new google.maps.LatLng(position.coords.latitude, position.coords.longitude);

            this.center = {
                lat: position.coords.latitude,
                lng: position.coords.longitude
            };

            try {
                this.mapMarker.setPosition(newPosition);
            } catch (e) {
                console.log(e)
            }

            this.mapMarker.addListener("click", () => {
                this.infoMarker.open(this.map, this.mapMarker);
                this.isVisible = !this.isVisible;
            });

            var deepPosition = {
                latitude: parseFloat(position.coords.latitude),
                longitude: parseFloat(position.coords.longitude),
            };

            this.map.panTo(new google.maps.LatLng(this.center.lat,this.center.lng));
            // this.$refs.mainMap.$mapObject.panTo(this.center);
        }
    }
}
</script>

<style lang="scss">
.myInfoWindow {
	width: 60px;
	height: 75px;
	overflow: hidden;
	img {
		width: 100%;
	}
}
</style>

<style lang="scss" scoped>
.phi-button {
	position: absolute;
	top: 10px;
	right: 10px;
	z-index: 2;
}
</style>
